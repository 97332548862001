import React, { Component } from 'react'

class About extends Component {
  render () {
    return (
      <p></p>
    )
  }
}

export default About
